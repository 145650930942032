<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <a @click="goBack" class="margin-bottom" style="border: 1px black"><span class="fa fa-long-arrow-left"></span>
          Back</a>
        <br />
        <br />
        <div class="card">
          <div class="card-header">
            <div class="cui__utils__heading mb-0">
              <strong>Edit Remote RTB Zone</strong>
            </div>
            <div class="text-muted">Edit Remote RTB Zone information</div>
          </div>
          <div class="card-body">
            <a-form v-if="remoteRTBZoneData" :form="form" @submit.prevent="handleSubmit">
              <div class="flex">
                <div class="w-100 -m">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Name">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Remote RTB Zone's name.</span>
                      </template>
                      <a-input type="text" placeholder="Remote RTB Zone Name" v-decorator="['name', {
                        initialValue: remoteRTBZoneData.Name,
                        rules: [
                          { required: true, message: 'Name is required.' },
                          { max: 64, message: 'Maximum 64 characters allowed.' }
                        ]
                      }]" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Zone URL">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Remote RTB Zone's URL.</span>
                      </template>
                      <a-input type="url" placeholder="Zone URL" v-decorator="['zoneUrl', {
                        initialValue: remoteRTBZoneData.ZoneUrl,
                        rules: [
                          { required: true, message: 'Zone URL is required.' },
                          { max: 256, message: 'Maximum 256 characters allowed.' }
                        ]
                      }]">
                      </a-input>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Description">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Remote RTB Zone's description.</span>
                      </template>
                      <a-input placeholder="Description" v-decorator="['description', {
                        initialValue: remoteRTBZoneData.Description,
                        rules: [{ max: 512, message: 'Maximum 512 characters allowed.' }]
                      }]" />
                    </a-tooltip>
                  </a-form-item>
                </div>

                <div class="w-100">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Impression Tracking Method">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>How advertiser tracks impressions for Remote RTB Zone. System will fire chosen pixel when
                          impression occurs (nurl or burl). If Markup Display value is chosen, the system will not
                          notify advertiser's server and advertiser will count impressions when their markup is
                          displayed. This value has an effect only for the impressions from connected Banner and Video
                          Zones, but not from RTB Zones.</span>
                      </template>
                      <a-select
                        v-decorator="['trackingMethod', { initialValue: remoteRTBZoneData.ImpressionTrackingMethod },]"
                        placeholder="Impression Tracking Method" :showSearch="true" :filterOption="true"
                        optionFilterProp="children">
                        <a-select-option :value="0">
                          nurl
                        </a-select-option>
                        <a-select-option :value="1">
                          burl
                        </a-select-option>
                        <a-select-option :value="2">
                          Markup Display
                        </a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Max. QPS">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Maximum queries per second - Maximum number of requests to make to the Remote RTB Zone in
                          one second. Set to 0 for unlimited requests.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Max. QPS"
                        v-decorator="['maxQps', { initialValue: remoteRTBZoneData.MaxQps }]" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Auto Optimize">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Enable Automatic requests optimization.</span>
                      </template>
                      <a-switch v-if="autoOpt !== undefined" checked-children="Enabled"
                        un-checked-children="Disabled" v-model="autoOpt" />
                    </a-tooltip>
                  </a-form-item>
                </div>
              </div>
              <a-divider style="margin-top: 34px;">Limits</a-divider>
              <div class="flex">
                <div class="w-100 -m">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Requests Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Number of requests to make per day. The system will even out distribution of requests
                          throughout
                          the day, ie. "day shaping". Set to 0 for unlimited requests.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Requests Daily Limit"
                        v-decorator="['requestsDailyLimit', { initialValue: remoteRTBZoneData.RequestsDailyLimit, rules: [{ validator: cpmValidator }] }]" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Bids Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Number of bids per day. The system will even out distribution of bids throughout
                          the day, ie. "day shaping". Set to 0 for unlimited bids.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Bids Daily Limit"
                        v-decorator="['bidsDailyLimit', { initialValue: remoteRTBZoneData.BidsDailyLimit, rules: [{ validator: cpmValidator }] }]" />
                    </a-tooltip>
                  </a-form-item>

                </div>
                <div class="w-100">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Impressions Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Number of impressions per day. The system will even out distribution of impressions throughout the day, ie. "day shaping". Set to 0 for unlimited impressions.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Impressions Daily Limit"
                        v-decorator="['impressionsDailyLimit', { initialValue: remoteRTBZoneData.ImpressionsDailyLimit, rules: [{ validator: cpmValidator }] }]" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Revenue Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Maximum daily amount (USD) to earn from advertiser. Set to 0 for unlimited amount.</span>
                      </template>
                      <a-input type="number" min="0" step="0.0001" placeholder="Revenue Daily Limit"
                        v-decorator="['revenueDailyLimit', { initialValue: remoteRTBZoneData.RevenueDailyLimit, rules: [{ validator: cpmValidator }] }]">
                        <a-tooltip slot="addonBefore" trigger="click">
                          <a-icon type="dollar" />
                        </a-tooltip>
                      </a-input>
                    </a-tooltip>
                  </a-form-item>
                </div>
              </div>
              <a-divider style="margin-top: 34px;">Supply Filters</a-divider>
              <div class="flex">
                <div class="w-100 -m">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Banner Enabled">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Enable Banner Ads from connected zones.</span>
                      </template>
                      <a-switch v-if="bannerEnabled !== undefined" checked-children="Enabled"
                        un-checked-children="Disabled" v-model="bannerEnabled" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Video Enabled">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Enable Video Ads from connected zones.</span>
                      </template>
                      <a-switch v-if="videoEnabled !== undefined" checked-children="Enabled"
                        un-checked-children="Disabled" v-model="videoEnabled" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Audio Enabled">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Enable Audio ads from connected zones.</span>
                      </template>
                      <a-switch v-if="audioEnabled !== undefined" checked-children="Enabled"
                        un-checked-children="Disabled" v-model="audioEnabled" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Native Enabled">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Enable Native Ads from connected zones.</span>
                      </template>
                      <a-switch v-if="nativeEnabled !== undefined" checked-children="Enabled"
                        un-checked-children="Disabled" v-model="nativeEnabled" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Pop Enabled">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Enable Pop Ads from connected zones.</span>
                      </template>
                      <a-switch v-if="popEnabled !== undefined" checked-children="Enabled"
                        un-checked-children="Disabled" v-model="popEnabled" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Web Enabled">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Enable Web traffic from connected zones.</span>
                      </template>
                      <a-switch v-if="webEnabled !== undefined" checked-children="Enabled" un-checked-children="Disabled"
                        v-model="webEnabled" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="App Enabled">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Enable App traffic from connected zones.</span>
                      </template>
                      <a-switch v-if="appEnabled !== undefined" checked-children="Enabled" un-checked-children="Disabled"
                        v-model="appEnabled" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Max. Bidfloor">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Remote RTB Zone will not participate in auction if connected supply zone has Bidfloor
                          greater than this value.</span>
                      </template>
                      <a-input type="float" min="0" max="20" step="0.0001" placeholder="Max. Bidfloor"
                        v-decorator="['maxBidfloor', { initialValue: remoteRTBZoneData.MaxBidfloor, rules: [{ validator: cpmValidator }] }]">
                        <a-tooltip slot="addonBefore" trigger="click">
                          <a-icon type="dollar" />
                        </a-tooltip>
                      </a-input>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Min. Tmax">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Remote RTB Zone will not participate in auction if connected supply zone has Tmax less
                          than this value.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Min. Tmax"
                        v-decorator="['minTmax', { initialValue: remoteRTBZoneData.MinTmax }]" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Max. IVT">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Remote RTB Zone will not participate in auction if connected supply zone has inventory with
                          IVT greater than this value.</span>
                      </template>
                      <a-input type="number" min="0" max="100" suffix="%" v-decorator="['maxIvt', {
                        initialValue: remoteRTBZoneData.MaxIvt,
                      }]" placeholder="Max. IVT">
                      </a-input>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Max. SIVT">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Remote RTB Zone will not participate in auction if connected supply zone has inventory with
                          SIVT greater than this value.</span>
                      </template>
                      <a-input type="number" min="0" max="100" suffix="%" v-decorator="['maxSivt', {
                        initialValue: remoteRTBZoneData.MaxSivt,
                      }]" placeholder="Max. SIVT">
                      </a-input>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Domains/Brands List Mode">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Block or allow publisher domains or brand names.</span>
                      </template>
                      <a-select
                        v-decorator="['domainsBrandsListMode', { initialValue: remoteRTBZoneData.DomainsBrandsListMode }]"
                        placeholder="Domains/Brands List Mode" :filterOption="true" optionFilterProp="children">
                        <a-select-option :value='0'>Blacklist</a-select-option>
                        <a-select-option :value='1'>Whitelist</a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Domains/Brands List">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>List of Domain Lists to block or allow.</span>
                      </template>
                      <a-select
                        v-decorator="['domainsBrandsList', { initialValue: (remoteRTBZoneData.DomainsBrandsList === '' ? [] : remoteRTBZoneData.DomainsBrandsList) }]"
                        placeholder="Please Select Domains/Brands Lists" :showSearch="true" :filterOption="true"
                        mode="multiple" optionFilterProp="children">
                        <a-select-option v-for="obj in activeDomainLists" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name }}
                        </a-select-option>
                      </a-select>
                      <!-- <div class="list-flex">
                        <a-select
                          v-decorator="['DomainsBrandsList', { initialValue: remoteRTBZoneData.DomainsBrandsList === '' ? [] : remoteRTBZoneData.DomainsBrandsList.split('\n') }]"
                          @change="handleDomainsBrands" placeholder="Domains/Brands List" :filterOption="true"
                          optionFilterProp="children" mode="tags" :tokenSeparators="[' ']" class="select-width">
                        </a-select>
                        <a-button class="btn-info copy-button-width" @click="copyList('brands')"><i
                            class="fa fa-copy"></i></a-button>
                      </div> -->
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Apps List Mode">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Block or allow publisher applications by their application identifiers.</span>
                      </template>
                      <a-select v-decorator="['appsListMode', { initialValue: remoteRTBZoneData.AppsListMode }]"
                        placeholder="Apps List Mode" :filterOption="true" optionFilterProp="children">
                        <a-select-option :value='0'>Blacklist</a-select-option>
                        <a-select-option :value='1'>Whitelist</a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Apps List">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>List of App Lists to block or allow.</span>
                      </template>
                      <a-select
                        v-decorator="['appsList', { initialValue: (remoteRTBZoneData.AppsList === '' ? [] : remoteRTBZoneData.AppsList) }]"
                        placeholder="Please Select App Lists" :showSearch="true" :filterOption="true" mode="multiple"
                        optionFilterProp="children">
                        <a-select-option v-for="obj in activeAppLists" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name }}
                        </a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                </div>
                <div class="w-100">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Non Empty Referer">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Ad impression or click with no referer will be filtered.</span>
                      </template>
                      <a-switch v-if="nonEmptyRefererEnabled !== undefined" checked-children="Enabled"
                        un-checked-children="Disabled" v-model="nonEmptyRefererEnabled" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Referer Match">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Referer in ad impression or click and site URL should match.</span>
                      </template>
                      <a-switch v-if="refererMatchEnabled !== undefined" checked-children="Enabled"
                        un-checked-children="Disabled" v-model="refererMatchEnabled" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="IP Match">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Real user's IP address in ad impression or click and user IP parameter should match.</span>
                      </template>
                      <a-switch v-if="ipMatchEnabled !== undefined" checked-children="Enabled"
                        un-checked-children="Disabled" v-model="ipMatchEnabled" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="UA Match">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Real user's User Agent in ad impression or click and user agent parameter should
                          match.</span>
                      </template>
                      <a-switch v-if="uaMatchEnabled !== undefined" checked-children="Enabled"
                        un-checked-children="Disabled" v-model="uaMatchEnabled" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :style="{ marginTop: '3.76rem' }" :label-col="labelCol" :wrapper-col="wrapperCol" label="Sizes List Mode">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Block or allow creatives' sizes.</span>
                      </template>
                      <a-select v-decorator="['sizesListMode', { initialValue: remoteRTBZoneData.SizesListMode }]"
                        placeholder="Sizes List Mode" :filterOption="true" optionFilterProp="children">
                        <a-select-option :value='0'>Blacklist</a-select-option>
                        <a-select-option :value='1'>Whitelist</a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Sizes List">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>List of creatives' sizes to block or allow.</span>
                      </template>
                      <a-select v-decorator="['sizesList', { initialValue: selectedSizes }]"
                        placeholder="Please select Sizes" :showSearch="true" :filterOption="true" mode="multiple"
                        optionFilterProp="children">
                        <a-select-option :value="`${size.size.width}x${size.size.height}`" v-for="(size, index) in sizes"
                          :key="index">{{ size.size.width }}x{{ size.size.height }}
                        </a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Pub ID List Mode">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Block or allow Pub IDs.</span>
                      </template>
                      <a-select v-decorator="['pubIdListMode', { initialValue: remoteRTBZoneData.PubIdListMode }]"
                        placeholder="Pub ID List Mode" :filterOption="true" optionFilterProp="children">
                        <a-select-option :value='0'>Blacklist</a-select-option>
                        <a-select-option :value='1'>Whitelist</a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Pub ID List">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>List of Pub IDs to block or allow.</span>
                      </template>
                      <div class="list-flex">
                        <a-select
                          v-decorator="['PubIdList', { initialValue: remoteRTBZoneData.PubIdList === '' ? [] : remoteRTBZoneData.PubIdList.split('\n') }]"
                          @change="handlePubIdList" placeholder="Pub ID List" :filterOption="true"
                          optionFilterProp="children" mode="tags" :tokenSeparators="[' ']">
                        </a-select>
                        <a-button class="btn-info copy-button-width" @click="copyList('pubIds')"><i
                            class="fa fa-copy"></i></a-button>
                      </div>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Seat List Mode">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Block or allow buyer seats (e.g., advertisers, agencies).</span>
                      </template>
                      <a-select v-decorator="['SeatListMode', { initialValue: remoteRTBZoneData.SeatListMode }]"
                        placeholder="Seat List Mode" :filterOption="true" optionFilterProp="children">
                        <a-select-option :value='0'>Blacklist</a-select-option>
                        <a-select-option :value='1'>Whitelist</a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Seat List">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>List of buyer seats to block or allow.</span>
                      </template>
                      <a-select
                        v-decorator="['SeatList', { initialValue: remoteRTBZoneData.SeatList === '' ? [] : remoteRTBZoneData.SeatList.split('\n') }]"
                        @change="handleSeatList" placeholder="Seat List" :filterOption="true" optionFilterProp="children"
                        mode="tags" :tokenSeparators="[' ']">
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="CRID List Mode">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Block or allow Creative IDs.</span>
                      </template>
                      <a-select v-decorator="['CridListMode', { initialValue: remoteRTBZoneData.CridListMode }]"
                        placeholder="CRID List Mode" :filterOption="true" optionFilterProp="children">
                        <a-select-option :value='0'>Blacklist</a-select-option>
                        <a-select-option :value='1'>Whitelist</a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="CRID List">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>List of Creative IDs to block or allow.</span>
                      </template>
                      <div class="list-flex">
                        <a-select
                          v-decorator="['CridList', { initialValue: remoteRTBZoneData.CridList === '' ? [] : remoteRTBZoneData.CridList.split('\n') }]"
                          @change="handleCridList" placeholder="CRID List" :filterOption="true"
                          optionFilterProp="children" mode="tags" :tokenSeparators="[' ']">
                        </a-select>
                        <a-button class="btn-info copy-button-width" @click="copyList('crids')"><i
                            class="fa fa-copy"></i></a-button>
                      </div>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="IP Blacklists">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>List of IP Lists to use for IP block. Only "Not Global" IP Lists can be chosen.</span>
                      </template>
                      <a-select
                        v-decorator="['ipBlacklists', { initialValue: (remoteRTBZoneData.IpBlacklists === '' ? [] : remoteRTBZoneData.IpBlacklists) }]"
                        placeholder="Please select IP Lists" :showSearch="true" :filterOption="true" mode="multiple"
                        optionFilterProp="children">
                        <a-select-option v-for="obj in activeIpLists" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name }}
                        </a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                </div>
              </div>
              <a-divider style="margin-top: 34px;">Supply Categories Filter</a-divider>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Enable IAB Categories">
                <a-tree checkable v-model="checkedComputedKeys" :auto-expand-parent="autoExpandParent"
                  :selected-keys="selectedKeys" :tree-data="treeData" @expand="onExpand" @select="onSelect"
                  optionFilterProp="children" />
              </a-form-item>
              <a-divider style="margin-top: 34px;">Supply</a-divider>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Banner Zones">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Banner Zones to connect to the Remote RTB Zone. This will make the Remote RTB Zone appear for
                      Banner Zone if all the targeting is matched.</span>
                  </template>
                  <a-select
                    v-decorator="['Bannerzones', { initialValue: (remoteRTBZoneData.Bannerzones === '' ? [] : remoteRTBZoneData.Bannerzones) }]"
                    placeholder="Please select Banner Zones" :showSearch="true" :filterOption="true" mode="multiple"
                    optionFilterProp="children">
                    <a-select-option class="zones" v-for="obj in activeBannerzones" :key="obj.Id">[{{ obj.Id }}] {{
                      obj.Name }}
                    </a-select-option>
                  </a-select>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Video Zones">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Video Zones to connect to the Remote RTB Zone. This will make the Remote RTB Zone appear for
                      Video Zone if all the targeting is matched.</span>
                  </template>
                  <a-select
                    v-decorator="['Videozones', { initialValue: (remoteRTBZoneData.Videozones === '' ? [] : remoteRTBZoneData.Videozones) }]"
                    placeholder="Please select Video Zones" :showSearch="true" :filterOption="true" mode="multiple"
                    optionFilterProp="children">
                    <a-select-option class="zones" v-for="obj in activeVideozones" :key="obj.Id">[{{ obj.Id }}] {{
                      obj.Name }}
                    </a-select-option>
                  </a-select>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="RTB Zones">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>RTB Zones to connect to the Remote RTB Zone. This will make the Remote RTB Zone appear in RTB
                      Zone response if all the targeting is matched.</span>
                  </template>
                  <a-select
                    v-decorator="['Rtbzones', { initialValue: (remoteRTBZoneData.Rtbzones === '' ? [] : remoteRTBZoneData.Rtbzones) }]"
                    placeholder="Please select RTB Zones" :showSearch="true" :filterOption="true" mode="multiple"
                    optionFilterProp="children">
                    <a-select-option class="zones" v-for="obj in activeRTBZones" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name
                    }}
                    </a-select-option>
                  </a-select>
                </a-tooltip>
              </a-form-item>

              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Pop Feeds">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Array of XML Pop Feeds' IDs to connect to the Remote RTB Zone. This will make the Remote RTB Zone appear in XML Pop Feed response if all the targeting is matched.</span>
                  </template>
                  <a-select
                    v-decorator="['Popfeeds', { initialValue: (remoteRTBZoneData.Popfeeds === '' ? [] : remoteRTBZoneData.Popfeeds) }]"
                    placeholder="Please select Pop Feeds" :showSearch="true" :filterOption="true" mode="multiple"
                    optionFilterProp="children">
                    <a-select-option class="zones" v-for="obj in activePopFeeds" :key="obj.Id">
                      [{{ obj.Id }}] {{obj.Name }}
                    </a-select-option>
                  </a-select>
                </a-tooltip>
              </a-form-item>

              <a-divider class="mt-3"></a-divider>
              <a-divider style="margin-top: 34px;">Targeting</a-divider>
              <a-tabs default-active-key="1" class="mb-4">
                <a-tab-pane key="1" tab="Countries">
                  <a-row>
                    <a-input-search placeholder="Filter Countries" style="width: 200px" class="mb-2"
                      v-model="filterSearch" />
                    <label class="cpointer"><input type="checkbox" class="ml-5 cpos-half"
                        v-model="displayEnabledCountries" /> Display Only Enabled</label>
                    <label class="cpointer"><input type="checkbox" class="ml-5 cpos-half" v-model="selectAllCountries"
                        @click="handleCountries" /> De/Select All</label>
                  </a-row>
                  <a-spin class="d-flex justify-content-center" v-if="enabledCountries.length === 0">
                    <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
                  </a-spin>
                  <ul v-else class="list-group list-group-flush countryList" style="border: 1px solid #e4e9f0;">
                    <li class="list-group-item treeContainer" v-for="(item, index) in enabledCountries" :key="index"
                      :style="item.Enabled ? 'background-color: rgb(237 237 243 / 38%);' : ''">
                      <label class="col-sm cpos cpointer">
                        <input type="checkbox" class="global-checkbox" v-model="item.Enabled"
                          @click="enableCountry($event, item)" />
                        <span class="col-sm"><img :src="matchIcon(countries, item.CC)" /> {{ item.Country }} ({{
                          item.CC
                        }})</span>
                      </label>
                    </li>
                  </ul>
                </a-tab-pane>
                <a-tab-pane key="2" tab="Operating Systems" force-render>
                  <treeListRtb :listData="remoteRTBZoneData.Os" type="os" v-model="OSData" :defaultVal="100">
                  </treeListRtb>
                </a-tab-pane>
                <a-tab-pane class="" style="overflow-x: scroll; overflow-y:hidden" key="3" tab="Browsers" force-render>
                  <treeListRtb :listData="remoteRTBZoneData.Browsers" type="browser" v-model="BrowserData"
                    :defaultVal="100"></treeListRtb>
                </a-tab-pane>
                <a-tab-pane key="4" tab="Device Types">
                  <ul class="list-group list-group-flush countryList" style="border: 1px solid #e4e9f0;">
                    <li v-for="item in deviceTypes" :key="item.index" class="list-group-item treeContainer"
                      :style="remoteRTBZoneData.DeviceTypes.includes(item.value) ? 'background-color: rgb(237 237 243 / 38%);' : ''">
                      <label class="col-sm cpos cpointer">
                        <input type="checkbox" class="global-checkbox" :checked="enabledDevice(item.value)"
                          @click="enableDevice($event, item.value)" />
                        <span class="col-sm">{{item.name}}</span>
                      </label>
                    </li>
                  </ul>
                </a-tab-pane>
              </a-tabs>
              <div class="ant-row ant-form-item">
                <div class="ant-col ant-col-xs-24 ant-col-sm-3 ant-form-item-label"></div>
                <div class="ant-col ant-col-xs-24 ant-col-sm-18 ant-form-item-control-wrapper">
                  <button type="submit" class="btn btn-success px-5">Save Changes</button>
                  <button type="button"
                    :class="'btn btn-' + (remoteRTBZoneData.Status === 0 ? 'danger' : 'primary') + ' px-5 ml-0 ml-lg-2'"
                    @click.prevent="toggleZoneStatus">
                    {{ remoteRTBZoneData.Status == 0 ? 'Deactivate' : 'Activate' }} Remote RTB Zone
                  </button>
                  <button type="button" class="btn px-5 ml-0 ml-lg-2" @click="goBack">Cancel</button>
                </div>
              </div>
            </a-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { Modal } from 'ant-design-vue'
import treeListRtb from '@/components/custom/TreeListRtb'
import staticCountryData from '@/resources/countries.json'
import zoneSize from '@/views/display/data/zoneSizeRemoteRTBZones.json'
import { getRemoteRTBZoneById } from '@/api/display/remotertbzone'
import { getIabCategories } from '@/api/info'
import Vue from 'vue'

const labelCol = {
  xs: { span: 24 },
  sm: { span: 6 },
}
const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 18 },
}

export default {
  components: { treeListRtb },
  computed: {
    ...mapState(['info', 'remotertbzone', 'rtbzone', 'videozone']),
    ...mapGetters('bannerzone', ['getBannerzones', 'getActiveBannerzones']),
    ...mapGetters('popfeed', ['getPopfeed', 'getActivePopFeeds']),
    ...mapGetters('videozone', ['getVideozones', 'getActiveVideozones']),
    ...mapGetters('rtbzone', ['getRTBZones', 'getActiveRTBZones']),
    ...mapGetters('ORTBiplist', ['getIpLists', 'getActiveIpLists']),
    ...mapGetters('applist', ['getAppLists', 'getActiveAppLists']),
    ...mapGetters('domainlist', ['getDomainLists', 'getActiveDomainLists']),
    activePopFeeds: function () {
      return this.getActivePopFeeds
    },
    checkedComputedKeys: {
      // getter
      get: function () {
        return this.treeData.length === 0 ? [] : this.checkedKeys
      },
      // setter
      set: function (newValue) {
        this.checkedKeys = newValue
      },
    },
    activeBannerzones: function () {
      return this.getActiveBannerzones
    },
    activeVideozones: function () {
      return this.getActiveVideozones
    },
    activeRTBZones: function () {
      return this.getActiveRTBZones
    },
    activeIpLists: function () {
      return this.getActiveIpLists.filter(el => el.Global === 0)
    },
    activeAppLists: function () {
      return this.getActiveAppLists.filter(el => el.Global === 0)
    },
    activeDomainLists: function () {
      return this.getActiveDomainLists.filter(el => el.Global === 0)
    },
    enabledCountries: function () {
      if (this.displayEnabledCountries === true) {
        return this.countriesList.filter(el => el.Enabled === true)
      } else if (this.filterSearch !== '') {
        return this.countriesList.filter(el => {
          return el.Country.toLowerCase().indexOf(this.filterSearch.toLowerCase()) !== -1 ||
            el.CC.toLowerCase().indexOf(this.filterSearch.toLowerCase()) !== -1
        })
      }
      return this.countriesList
    },
  },
  data() {
    return {
      sizes: zoneSize,
      selectedSizes: [],
      remoteRTBZoneData: null,
      countries: staticCountryData,
      bannerEnabled: undefined,
      videoEnabled: undefined,
      audioEnabled: undefined,
      nativeEnabled: undefined,
      popEnabled: undefined,
      webEnabled: undefined,
      appEnabled: undefined,
      nonEmptyRefererEnabled: undefined,
      autoOpt: undefined,
      refererMatchEnabled: undefined,
      ipMatchEnabled: undefined,
      uaMatchEnabled: undefined,
      form: this.$form.createForm(this),
      pubIdList: [],
      seatList: [],
      cridList: [],
      seatListMode: undefined,
      cridListMode: undefined,
      domainsBrandsListMode: undefined,
      checkedKeys: [],
      checkedAds: [],
      selectedAdKeys: [],
      autoExpandParent: false,
      selectedKeys: [],
      treeData: [],
      unformattedResponse: [],
      OSData: null,
      labelCol,
      wrapperCol,
      displayEnabledCountries: false,
      BrowserData: null,
      filterSearch: '',
      countriesList: [],
      selectAllCountries: false,
      deviceTypes: [
        {
          name: 'Desktop',
          value: 1,
        },
        {
          name: 'Mobile',
          value: 2,
        },
        {
          name: 'Tablet',
          value: 3,
        },
        {
          name: 'CTV',
          value: 4,
        },
        {
          name: 'Other',
          value: 5,
        },
      ],
    }
  },
  methods: {
    enabledDevice(value) {
      if (this.remoteRTBZoneData.DeviceTypes.includes(value)) {
        return true
      } else {
        return false
      }
    },
    enableDevice(event, value) {
      const index = this.remoteRTBZoneData.DeviceTypes.indexOf(value)
      if (index === -1) {
        this.remoteRTBZoneData.DeviceTypes.push(value)
      } else {
        this.remoteRTBZoneData.DeviceTypes.splice(index, 1)
      }
    },
    copyList(type) {
      if (type === 'pubIds') {
        navigator.clipboard.writeText(this.remoteRTBZoneData.PubIdList)
        Vue.prototype.$notification.success({
          message: 'Pub ID List copied to clipboard.',
        })
      } else if (type === 'crids') {
        navigator.clipboard.writeText(this.remoteRTBZoneData.CridList)
        Vue.prototype.$notification.success({
          message: 'CRID List copied to clipboard.',
        })
      }
    },
    handleCountries() {
      this.countriesList.forEach(el => {
        el.Enabled = !this.selectAllCountries
      })
    },
    matchIcon(array, code) {
      const cc = array.find(element => element.cc === code)
      if (cc === undefined) {
        return false
      } else {
        const image = cc.image
        return image
      }
    },
    enableCountry(event, row) {
      const itemIndex = this.remoteRTBZoneData.Countries.findIndex(el => el.CC === row.CC)
      if (itemIndex === -1) {
        this.remoteRTBZoneData.Countries.push({
          Country: row.Country,
          CC: row.CC,
          Enabled: true,
        })
      } else {
        this.remoteRTBZoneData.Countries[itemIndex].Enabled = event.target.checked
      }
    },
    handlePubIdList(data) {
      this.remoteRTBZoneData.PubIdList = data.join('\n')
    },
    handleCridList(data) {
      this.remoteRTBZoneData.CridList = data.join('\n')
    },
    $_formatTreeData(type = 'os') {
      const searchData = type === 'os' ? this.OSData : this.BrowserData
      const formatedData = []
      searchData.forEach(el => {
        // Add if all versions selected
        if (el.enabled) {
          const temp = {
            Family: el.Family,
            BidAdjustment: 100,
          }
          if (el.Versions.length > 0) {
            const tmpV = []
            el.Versions.forEach(ss => {
              if (ss.enabled) {
                const temp2 = {
                  BidAdjustment: 100,
                  Major: ss.Major,
                }
                if (typeof ss.Minor !== 'undefined') temp2.Minor = ss.Minor
                tmpV.push(temp2)
              }
            })
            temp.Versions = tmpV
          }
          if (typeof el.AutoIncludeNewBrowsers !== 'undefined') temp.AutoIncludeNewBrowsers = el.AutoIncludeNewBrowsers ? 1 : 0
          formatedData.push(temp)
          return
        }
        // Filter versions
        const versions = []
        el.Versions.length > 0 && el.Versions.forEach(ver => {
          if (ver.enabled) {
            const temp = {
              BidAdjustment: 100,
              Major: ver.Major,
            }
            if (typeof ver.Minor !== 'undefined') temp.Minor = ver.Minor
            versions.push(temp)
          }
        })
        // Add if one or more versions is used
        if (versions.length > 0) {
          const tmp = {
            Family: el.Family,
            BidAdjustment: 100,
            Versions: versions,
          }
          if (typeof el.AutoIncludeNewBrowsers !== 'undefined') tmp.AutoIncludeNewBrowsers = el.AutoIncludeNewBrowsers ? 1 : 0
          formatedData.push(tmp)
        }
      })
      return formatedData
    },
    cpmValidator(rule, value, message) {
      if (value === '') {
        message()
      } else if (isNaN(parseFloat(value))) {
        message('Invalid number.')
      } else if (parseFloat(value) < 0) {
        message('Value can not be negative.')
      }
      message()
    },
    handleSeatList(data) {
      this.remoteRTBZoneData.SeatList = data.join('\n')
    },
    onAdSelect(selectedAdKeys, info) {
      // console.log('onSelect', info)
      this.selectedAdKeys = selectedAdKeys
    },
    onCheck(checkedKeys) {
      // console.log('onCheck', checkedKeys)
      this.checkedKeys = checkedKeys
    },
    onBlockedAdCheck(checkedAds) {
      // console.log('onCheck', checkedAds)
      this.checkedAds = checkedAds
    },
    onExpand(expandedKeys) {
      // console.log('onExpand', expandedKeys)
      // if not set autoExpandParent to false, if children expanded, parent can not collapse.
      // or, you can remove all expanded children keys.
      this.expandedKeys = expandedKeys
      this.autoExpandParent = false
    },
    onSelect(selectedKeys, info) {
      this.selectedKeys = selectedKeys
    },
    goBack() {
      // this.$router.go(-1)
      this.$router.push({ name: 'remotertbzones' })
    },
    handleSubmit() {
      const countries = []
      this.countriesList.forEach(el => {
        if (el.Enabled) {
          countries.push({
            CC: el.CC,
            BidAdjustment: 100,
          })
        }
      })
      const oss = this.$_formatTreeData('os')
      const browsers = this.$_formatTreeData('browser')

      this.form.validateFields((err, values) => {
        if (!err) {
          values.Id = this.remoteRTBZoneData.Id
          values.SeatList = this.remoteRTBZoneData.SeatList
          values.CridList = this.remoteRTBZoneData.CridList
          values.seatListMode = this.remoteRTBZoneData.SeatListMode
          values.DomainsBrandsListMode = this.remoteRTBZoneData.DomainsBrandsListMode
          values.PubIdList = this.remoteRTBZoneData.PubIdList
          values.DeviceTypes = this.remoteRTBZoneData.DeviceTypes
          values.BannerEnabled = this.bannerEnabled ? 1 : 0
          values.VideoEnabled = this.videoEnabled ? 1 : 0
          values.AudioEnabled = this.audioEnabled ? 1 : 0
          values.NativeEnabled = this.nativeEnabled ? 1 : 0
          values.PopEnabled = this.popEnabled ? 1 : 0
          values.WebEnabled = this.webEnabled ? 1 : 0
          values.AppEnabled = this.appEnabled ? 1 : 0
          values.NonEmptyReferer = this.nonEmptyRefererEnabled ? 1 : 0
          values.AutoOptimize = this.autoOpt ? 1 : 0
          values.RefererMatch = this.refererMatchEnabled ? 1 : 0
          values.IpMatch = this.ipMatchEnabled ? 1 : 0
          values.UaMatch = this.uaMatchEnabled ? 1 : 0
          if (values.zoneUrl !== '' && values.zoneUrl) {
            values.zoneUrl = values.zoneUrl.replace(/ /g, '')
          }
          const parentSelectedIab = this.checkedKeys.filter(checkedKey => this.unformattedResponse.includes(checkedKey))
          const array = this.treeData.map(element => parentSelectedIab.includes(element.key) ? element.children : []).flat().map(el => el.key)
          const newArr = this.checkedKeys.filter(el => !array.includes(el))
          values.IabCategories = newArr
          this.$store.dispatch('remotertbzone/UPDATE_ZONE', {
            id: this.remoteRTBZoneData.Id,
            payload: {
              ...values,
              DefaultCPM: 1,
              Browsers: browsers,
              Os: oss,
              Countries: countries,
            },
            callback: function () {
              this.$router.push({ name: 'remotertbzones' })
            }.bind(this),
          })
        }
      })
    },
    $_getContainer(el, tag = 'LI') {
      return el.tagName === tag ? el : this.$_getContainer(el.parentElement, tag)
    },
    toggleZoneStatus() {
      const status = this.remoteRTBZoneData.Status === 0 ? 'Deactivate' : 'Activate'
      Modal.confirm({
        title: 'Are you sure you want to change Remote RTB Zone status?',
        content: h => <div>{status} Remote RTB Zone <strong></strong>?</div>,
        onOk: function () {
          const newStatus = this.remoteRTBZoneData.Status === 0 ? 1 : 0
          this.$store.dispatch('remotertbzone/CHANGE_STATUS', {
            id: this.remoteRTBZoneData.Id,
            status: newStatus,
          })
        }.bind(this),
      })
    },
    populateCountry: function () {
      if (this.countriesList.length > 0 && this.remoteRTBZoneData !== null && this.remoteRTBZoneData.Countries.length > 0) {
        for (const index in this.countriesList) {
          const country = this.remoteRTBZoneData.Countries.filter(ob => ob.CC === this.countriesList[index].CC)
          if (typeof country[0] !== 'undefined') {
            this.countriesList[index].Enabled = true
          }
        }
      }
    },
  },
  watch: {
    remoteRTBZoneData(val) {
      if (val !== undefined) {
        this.bannerEnabled = val.BannerEnabled === 1
        this.videoEnabled = val.VideoEnabled === 1
        this.audioEnabled = val.AudioEnabled === 1
        this.nativeEnabled = val.NativeEnabled === 1
        this.popEnabled = val.PopEnabled === 1
        this.webEnabled = val.WebEnabled === 1
        this.appEnabled = val.AppEnabled === 1
        this.nonEmptyRefererEnabled = val.NonEmptyReferer === 1
        this.autoOpt = val.AutoOptimize === 1
        this.refererMatchEnabled = val.RefererMatch === 1
        this.ipMatchEnabled = val.IpMatch === 1
        this.uaMatchEnabled = val.UaMatch === 1
      }
    },
  },
  created() {
    getIabCategories().then(response => {
      const formattedResponse = []
      response.forEach((el, id) => {
        const temp = {
          title: '(' + el.Name + ')' + ' ' + el.Title,
          key: el.Name,
          children: [],
        }
        el.Subcategories.forEach((element) => {
          temp.children.push({
            title: '(' + element.Name + ')' + ' ' + element.Title,
            key: element.Name,
          })
        })
        formattedResponse.push(temp)
        this.treeData = formattedResponse
      })
      response.forEach(element => {
        this.unformattedResponse.push(element.Name)
      })
    })
    this.$store.dispatch('info/LOAD_COUNTRIES').then((res) => {
      res.forEach((el) => {
        this.countriesList.push({
          Country: el.Country,
          CC: el.CC,
          Enabled: false,
        })
      })
      this.populateCountry()
    })
    this.$store.dispatch('info/LOAD_OSS')
    this.$store.dispatch('info/LOAD_BROWSERS')
    this.$store.dispatch('popfeed/LOAD_FEEDS_SHORT')
    this.$store.dispatch('bannerzone/LOAD_BANNERZONES_SHORT')
    this.$store.dispatch('videozone/LOAD_VIDEOZONES_SHORT')
    this.$store.dispatch('rtbzone/LOAD_RTBZONES_SHORT')
    this.$store.dispatch('ORTBiplist/LOAD_IPLISTS')
    this.$store.dispatch('applist/LOAD_APPLISTS')
    this.$store.dispatch('domainlist/LOAD_DOMAINLISTS')
    getRemoteRTBZoneById(this.$route.params.id).then((response) => {
      this.remoteRTBZoneData = response
      this.checkedKeys = this.remoteRTBZoneData.IabCategories
      document.title = `${this.remoteRTBZoneData.Name} - RTB.ME | Edit Remote RTB Zone` || document.title
      // this.selectedSizes = response.SizesList.filter((el) => this.sizes.includes(el))
      this.sizes.forEach(el => {
        if (response.SizesList.includes(`${el.size.width}x${el.size.height}`)) {
          this.selectedSizes.push(`${el.size.width}x${el.size.height}`)
        }
      })
      this.populateCountry()
    })
  },
}
</script>
<style lang="css" scoped>
.ant-select-dropdown-menu-item-selected.zones {
  color: #595c97 !important;
  font-weight: 600 !important;
  display: none !important;
}

.list-flex {
  display: flex;
  align-content: flex-start;
}

.select-width {
  width: 90%;
}

.copy-button-width {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.1rem !important;
  height: 2.1rem !important;
}

.ant-form-item {
  margin-bottom: 5px !important;
}

.custom-table th,
.custom-table td {
  padding: 0 0 0 6px !important;
  height: 30;
  text-align: center;
}

.no-records-to-show {
  text-align: center;
  text-decoration: none;
  font-weight: normal;
}

.ant-table-small {
  border: none !important;
}

/** */
.arrow.left::before {
  content: "▶";
  height: 10px;
  margin-right: 5px;
}

.arrow.down::before {
  content: "▼";
  height: 10px;
  margin-right: 5px;
}

.bid-adjustment {
  display: inline;
  width: 80px !important;
  padding: 0 5px;
  font-size: 0.9rem;
  height: 1.7rem;
}

.checkbox {
  position: relative;
  top: 2px;
}

.list-group-item {
  padding: .1rem 1.25rem !important;
}

.treeContainer {
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
}

.treeContainerV {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  /* flex-wrap: wrap; */
}

label {
  opacity: 0.8;
}

.no-border {
  border: none;
}

.list-group-flush>.list-group-item:last-child {
  border-bottom-width: 1px !important;
}

.global-checkbox {
  position: relative;
  top: 2px;
}

.cpos-half {
  position: relative;
  top: 2px;
}

.cpos {
  position: relative;
  top: 4px;
}

.version-checkbox {
  position: relative;
  margin-right: 5px;
  top: 2px;
}

.no-margin {
  margin-bottom: 0;
}

.cpointer {
  cursor: pointer;
}

@media only screen and (min-width: 768px) {
  .flex {
    display: flex;
    flex-direction: col;
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .-m {
    margin-right: -4rem;
  }

  .break-line>>>li .ant-tree-node-content-wrapper {
    margin-bottom: 5px;
    padding-top: 4px;
    display: inline-block !important;
    white-space: pre-wrap !important;
    line-height: 15px !important;
    width: 90% !important;
  }
}
</style>
